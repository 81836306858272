import { Environment } from 'enums'

const env = {
  SERVER: 'http://localhost:8082',
  API_URL: 'http://localhost:8082/api',
  GHL: {
    V2_BASE_URL: 'https://api.msgsndr.com',
    CLIENT_ID: '6246e2fcfc3dabe2d3301bc5-l1gcm0ww',
    CLIENT_SECRET: '67be1628-cb7c-4530-95a3-a83e60093cd0',
    SCOPE:
      'contacts.readonly contacts.write locations.readonly locations/customFields.readonly locations/customFields.write workflows.readonly users.readonly users.write campaigns.readonly conversations/message.readonly conversations/message.write locations/customValues.readonly locations/customValues.write opportunities.readonly opportunities.write forms.readonly links.readonly links.write surveys.readonly locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write locations/templates.readonly',
    REDIRECT: {
      SUPER_ADMIN: 'http://localhost:3000/integrations/ghl/sadmin',
    },
  },
  SUPER_ADMIN: '656607903041e1ca410cfc49',
}

if (process.env.REACT_APP_ENV === Environment.STAGING) {
  env.API_URL = 'https://dev.api.walletcampaigns.com/api'
  env.SERVER = 'https://dev.api.walletcampaigns.com'
  env.GHL.REDIRECT.SUPER_ADMIN =
    'https://dev.superadmin.walletcampaigns.com/integrations/ghl/sadmin'
}

if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env.API_URL = 'https://api.walletcampaigns.com/api'
  env.SERVER = 'https://api.walletcampaigns.com'
  env.GHL.REDIRECT.SUPER_ADMIN =
    'https://superadmin.walletcampaigns.com/integrations/ghl/sadmin'
}

export default env
