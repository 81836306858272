const TYPES = {
  AGENCY: 'agency_user',
  LOCATION: 'location_user',
  SUBSCRIBER: 'subscriber',
  SUPER_ADMIN: 'super_admin',
  SUPPORT: 'support',

}

export { TYPES }
