import React from 'react'
import { useAuth } from 'context/auth'
import AuthRoutes from './auth-routes'
import AdminRoutes from './admin-routes'
import InvalidAccessRoutes from './unauthorize-routes'

import { TYPES } from 'enums/user'

const Routes = () => {
  const { currentUser } = useAuth()
  const routes = []
  
  // PUBLIC
  if (!currentUser) return routes.concat(AuthRoutes)

  // PREMISSION DENIED
  if (currentUser && !currentUser.roles?.includes(TYPES.SUPPORT))
    return routes.concat(InvalidAccessRoutes)

  // ADMIN
  if (currentUser) return routes.concat(AdminRoutes)

  return routes
}

export default Routes
