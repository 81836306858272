import { ReqMethods } from 'enums'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import QueryString from 'qs'
import Api from 'utils/api'
import env from 'configuration'
import { useSuperAdmin } from 'context/super-admin'

const GHL_OAUTH = env.GHL.V2_BASE_URL + '/oauth/token'

function Associate() {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { setSuperAdmin } = useSuperAdmin()

  const code = new URLSearchParams(search).get('code')

  const [error, setError] = useState()

  const associate = async ({ location }, creds) => {
    console.log(location)
    const payload = {
      crm: {
        name: location.name,
        address: location.address,
        email: location.email,
        location_id: creds.locationId,
        company_id: location.companyId,
        access_token: creds.access_token,
        refresh_token: creds.refresh_token,
      },
    }

    const { response } = await Api('/super_admin', 'put', { payload })

    if (!response)
      return setError(
        'Something went wrong while integration, please try later'
      )
    setSuperAdmin(response.data)
    return navigate(-2)
  }

  const getLocation = async (creds) => {
    const payload = {
      method: ReqMethods.GET,
      path: `${env.GHL.V2_BASE_URL}/locations/${creds.locationId}`,
      key: creds.access_token,
      refresh_token: creds.refresh_token,
    }

    const { response } = await Api('/utils/crm/call_api', 'post', { payload })

    if (!response)
      return setError(
        'Something went wrong while integration, please try later'
      )
    // console.log(response.data)

    return response && associate(response.data, creds)
  }

  const getCreds = async () => {
    const payload = {
      method: ReqMethods.POST,
      path: GHL_OAUTH,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      payload: QueryString.stringify({
        client_id: env.GHL.CLIENT_ID,
        client_secret: env.GHL.CLIENT_SECRET,
        grant_type: 'authorization_code',
        code,
        refresh_token: '',
      }),
    }

    const { response } = await Api('/utils/crm/call_api', 'post', { payload })

    console.log(response)

    if (!response)
      return setError(
        'Something went wrong while integration, please try later'
      )

    return response && getLocation(response.data)
  }

  const onLoad = () => {
    getCreds()
  }

  useEffect(onLoad, [])

  return (
    <div className='bg-slate-200 h-[100vh] p-3 text-lg font-semibold'>
      {!error && <div>Integrating please wait...</div>}
      {!!error && (
        <div>
          {error}

          <button
            type='button'
            onClick={() => navigate(-2)}
            class='text-white ml-2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
          >
            Go Back
          </button>
        </div>
      )}
    </div>
  )
}

export default Associate
