import React, { lazy } from 'react'
import ComponentLoader from './component-loader'
import AdminLayout from 'screens/admin/AdminLayout'
import { Navigate } from 'react-router-dom'
import Associate from 'screens/public/integration/ghl/associate'

const Chat = ComponentLoader(lazy(() => import('screens/admin/Chat')))
const TicketModule = ComponentLoader(lazy(() => import('screens/admin/Ticket')))
const Ticket = ComponentLoader(
  lazy(() => import('screens/admin/Ticket/ticket-list'))
)
const ViewTicket = ComponentLoader(
  lazy(() => import('screens/admin/Ticket/ticket-view'))
)

const Profile = ComponentLoader(
  lazy(() => import('screens/admin/Profile/profile'))
)

const AdminRoutes = [
  {
    path: '',
    element: <AdminLayout />,
    children: [
      {
        path: 'chat',
        element: <Chat />,
      },
      {
        path: 'ticket',
        element: <TicketModule />,
        children: [
          {
            path: '',
            index: true,
            element: <Ticket />,
          },
          {
            path: 'view',
            element: <ViewTicket />,
          },
        ],
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: '',
        index: true,
        element: <Navigate replace to={'/chat'} />,
      },
      {
        path: '*',
        index: true,
        element: <Navigate replace to={'/appsales'} />,
      },
    ],
  },
  {
    path: 'integrations',
    children: [
      {
        path: 'ghl',
        children: [
          {
            path: 'sadmin',
            element: <Associate />,
          },
        ],
      },
    ],
  },
  {
    path: '',
    index: true,
    element: <Navigate replace to={'/'} />,
  },
  {
    path: '*',
    index: true,
    element: <Navigate replace to={'/'} />,
  },
]

export default AdminRoutes
