import Sidebar from 'component/Sidebar/sidebar'
import { ChatConnectionProvider } from 'context/chat-connection'
import { SuperAdminProvider } from 'context/super-admin'
import React from 'react'
import { Outlet } from 'react-router-dom'

function AdminLayout() {
  return (
    <SuperAdminProvider>
      <ChatConnectionProvider>
        <Sidebar />
        <main>
          <div className="p-8 sm:ml-64 mt-14">
            <Outlet />
          </div>
        </main>
      </ChatConnectionProvider>
    </SuperAdminProvider>
  )
}

export default AdminLayout
